<template>
  <div>
    <head-layout :head-btn-options="headBtnOptions"
                 :head-title="this.$route.query.type == 'edit' ? $t('cip.plat.app.appMenu.title.editHeadTitle') : $t('cip.plat.app.appMenu.title.addHeadTitle')"
                 @head-save-cancel="headSave(true)" @head-save="headSave()" @head-cancel="headCancel">
    </head-layout>
    <!-- <form-layout ref="formLayout" icon="" :head-title="headtitle" page-type="self" :option="option"
    :btn-options="btnOptions" @form-btn-save-click="save" :dataForm="form"></form-layout>-->
    <form-layout :column="column" :dataForm.sync="dataObj" ref="formLayout"></form-layout>
  </div>
</template>

<script>
let baseUrl = '/api/sinoma-system/dict-biz/dictionary?code='
let dicUrl = '/api/sinoma-system/dict/dictionary?code='
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {update, getAppMenuTree as getMenuTree, getMenu} from "@/api/system/menu";
import iconList from "@/config/iconList";
import {mapGetters} from "vuex";

export default {
  components: {FormLayout, HeadLayout},
  computed: {
    ...mapGetters(['language']),
  },
  data() {
    return {
      parentId: 0,
      headBtnOptions: [{
        label: this.$t('cip.cmn.btn.saveBtn'),
        emit: "head-save",
        type: "button",
        icon: "",
        btnOptType: 'save',
      },
        {
          label: this.$t('cip.cmn.btn.saveBackBtn'),
          emit: "head-save-cancel",
          type: "button",
          icon: ""
        }, {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: 'cancel',
        }],
      dataObj: {},
      column: [
        {
          label: this.$t("cip.plat.app.appMenu.field.name"),
          prop: "name",
          search: true,
          isGlobal: true, //是否支持国际化
          rules: [
            {
              required: true,
              validator: this.validateName,
              trigger: "blur"
            }
          ]
        },
        {
          label: this.$t("cip.plat.app.appMenu.field.path"),
          prop: "path",
          message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.app.appMenu.field.path"),
          // rules: [
          //   {
          //     required: true,
          //     message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.app.appMenu.field.path"),
          //     trigger: "blur"
          //   }
          // ]
        },
        {
          label: this.$t("cip.plat.app.appMenu.field.code"),
          prop: "code",
          rules: [
            {
              required: true,
              message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.app.appMenu.field.code"),
              trigger: "blur"
            },
            {
              pattern: /^[^\s]+(\s+[^\s]+)*$/,
              message: this.$t('cip.cmn.rule.deleteWarning'),
            }
          ]
        },
        {
          label: this.$t("cip.plat.app.appMenu.field.parentId"),
          prop: "parentId",
          type: "tree",
          dicData: [],
          hide: true,
          addDisabled: false,
          props: {
            label: "title"
          },
          rules: [
            {
              required: false,
              message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.app.appMenu.field.parentId"),
              trigger: "click"
            }
          ],
          control: (val) => {
            if (val.length > 1) {
              return {
                typeSetting: {
                  display: false
                }
              }
            } else {
              return {
                typeSetting: {
                  display: true
                }
              }
            }
          },
        },
        {
          label: '默认菜单',
          type: "select",
          prop: "isDefault",
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dataType: 'number',
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=yes_no",
        },
        {
          label: this.$t("cip.plat.app.appMenu.field.platform"),
          type: "select",
          dicUrl: "/api/sinoma-system/dict/dictionary?code=user_type",
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          span: 8,
          dataType: "number",
          prop: "platform",
          disabled: true,
          display: false,
          value: "2",
          rules: [{
            required: true,
            message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.app.appMenu.field.platform"),
            trigger: "blur"
          }]
        },

        {
          label: this.$t("cip.plat.app.appMenu.field.category"),
          prop: "category",
          type: "select",
          // dicData: [
          //   {
          //     label: this.$t("cip.plat.app.appMenu.field.catelog"),
          //     value: 0
          //   },
          //   {
          //     label: this.$t("cip.plat.app.appMenu.field.menus"),
          //     value: 1
          //   },
          //   {
          //     label: this.$t("cip.plat.app.appMenu.field.button"),
          //     value: 2
          //   }
          // ],
          dataType: 'number',
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=catelog_menus_button",
          hide: true,
          value: 1,
          display: false,
          rules: [
            {
              required: true,
              message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.app.appMenu.field.category"),
              trigger: "blur"
            }
          ]
        },
        // {
        //   label: this.$t("cip.plat.app.appMenu.field.alias"),
        //   prop: "alias",
        //   search: true,
        //   isGlobal:true, //是否支持国际化
        //   rules: [
        //     {
        //       required: true,
        //       message: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.app.appMenu.field.alias"),
        //       trigger: "submit"
        //     }
        //   ]
        // },
        // {
        //   label: this.$t("cip.plat.app.appMenu.field.isOpen"),
        //   prop: "isOpen",
        //   type: "select",
        //   disabled: false,
        //   dicData: [
        //     {
        //       label: this.$t("cip.plat.app.appMenu.field.no"),
        //       value: 1
        //     },
        //     {
        //       label: this.$t("cip.plat.app.appMenu.field.yes"),
        //       value: 2
        //     }
        //   ],
        //   value: 1,
        //   rules: [
        //     {
        //       required: true,
        //       message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.app.appMenu.field.isOpen"),
        //       trigger: "blur"
        //     }
        //   ]
        // },
        {
          label: this.$t("cip.plat.app.appMenu.field.color"),
          prop: "color",
          type: "color",
          span: 8,
          message: this.$t(`cip.cmn.rule.inputWarning`) + this.$t(`cip.plat.app.appMenu.field.color`),
          rules: [
            {
              validator: this.validateColor,
              trigger: "blur",
            },
          ]
        },
        {
          label: this.$t("cip.plat.app.appMenu.field.status"),
          prop: "status",
          type: "radio",
          // dicData: [
          //   {
          //     label: this.$t("cip.plat.app.appMenu.field.yes"),
          //     value: 0
          //   },
          //   {
          //     label: this.$t("cip.plat.app.appMenu.field.no"),
          //     value: 1
          //   }
          // ],
          dataType: 'number',
          value: 0,
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: "/api/sinoma-system/dict/dictionary?code=yes_no",
          hide: true
        },
        {
          label: this.$t("cip.plat.app.appMenu.field.sort"),
          prop: "sort",
          span: 8,
          type: "number",
          minRows: 0,
          maxRows: 1000000000,
          rules: [
            {
              required: true,
              message: this.$t(`cip.cmn.rule.inputWarning`) + this.$t("cip.plat.app.appMenu.field.sort"),
              trigger: "blur",
            },
          ],
        },
        {
          label: this.$t('cip.plat.app.appMenu.field.typeSetting'),
          prop: 'typeSetting',
          labelWidth: 100,
          span: 8,
          type: 'select',
          dataType: "string",
          //dicUrl: `${baseUrl}list_layout`,
          dicUrl: "/api/sinoma-system/dict/dictionary?code=list_layout",
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.app.appMenu.field.typeSetting'),
              trigger: "blur"
            },
          ]
        },
        {
          label: this.$t('cip.plat.app.appMenu.field.isLink'),
          type: "select",
          prop: "isLink",
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dataType: 'number',
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=yes_no",
        },
        {
          label: this.$t('cip.plat.app.appMenu.field.filePath'),
          prop: 'filePath',
          type: 'upload',
          dataType: 'string',
          drag: true,
          loadText: this.$t('cip.plat.app.appMenu.field.loadText'),
          propsHttp: {
            url: 'link',
            name: 'name',
            res: 'data'
          },
          action: "/api/sinoma-resource/oss/endpoint/put-file-attach-name/platform",
          span: 8,
          change: ({value, column}) => {
            let split = value.split(",");
            console.log(split);
            if (split.length > 1) {
              this.dataForm.filePath = split[1];
            }
          },
          readonly: this.viewFlag,
          message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.app.appMenu.field.filePath'),
          // rules: [{
          //   required: true,
          //   message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.app.appMenu.field.filePath'),
          //   trigger: "blur"
          // }],
        },
        {
          label: this.$t("cip.plat.app.appMenu.field.remark"),
          prop: "remark",
          type: "textarea",
          minRows: 4,
          span: 24,
          maxlength: 200,
          showWordLimit: true,
          hide: true
        }
      ]
    }
  },
  created() {
    console.log(this.$route.query)
    this.initData()
    if (this.$route.query.type == 'edit') {
      // console.log(decodeURIComponent(this.$route.query.data))
      // this.dataObj = JSON.parse(decodeURIComponent(this.$route.query.data))
      // const column = this.findObject(this.optionParent.column, "code");
      // column.display = false
      getMenu(this.$route.query.id).then(res => {
        const {data} = res;
        this.dataObj = data.data;
      })
    } else if (this.$route.query.type == 'addChild') {
      this.parentId = this.$route.query.id;
      const column = this.findObject(this.column, "parentId");
      column.value = this.$route.query.id;
      column.addDisabled = true;
    }
  },
  mounted() {
    let {id, type} = this.$route.query;
    this.type = type;
    // if (['view', 'edit'].includes(this.type)) {
    //   this.dataObj.parentId = id
    //   this.initData()
    // }
  },

  methods: {
    headSave(cancel = false) {
      this.$refs.formLayout.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.dataObj.parenId != undefined) {
            this.$refs.formLayout.dataForm.typeSetting = ''
          }
          // if(!this.dataObj.parentId && this.dataObj.category != 1){
          //   this.$message.error("顶级菜单只能选择菜单")
          //   return
          // }
          this.$loading()
          update(this.dataObj).then(res => {
            const {msg, data} = res.data;
            if (res.data.code == 200) {
              this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
            } else {
              this.$message.success(msg);
            }
            if (cancel) {
              this.$router.$avueRouter.closeTag();
              this.$router.back();
            } else {
              //刷新数据

              this.dataObj = {}
              Object.assign(this.dataObj, data)
              this.dataForm = res.data.data;
              this.init()
              this.type = 'edit';
            }
          })
            .finally(() => {
              this.$loading().close()
              this.$refs.formLayout.$refs.form.allDisabled = false;
            })
        }
      })
    },
    // headSave(type) {
    //   console.log(type);
    //   this.$refs.formLayout.$refs.form.validate(valid => {
    //     if (valid) {
    //       let obj = this.$refs.formLayout.dataForm
    //       let requestType = obj.hasOwnProperty('id') ? update : add
    //       console.log(obj.hasOwnProperty('id'));
    //       requestType(obj).then((res) => {
    //         if (type == 'back') {
    //           this.headCancel()
    //         }
    //
    //         this.$message({
    //           type: "success",
    //           message: this.$t("cip.cmn.msg.success.operateSuccess"),
    //         });
    //         this.isShow = false
    //         //
    //       }, error => {
    //         window.console.log(error);
    //
    //         this.$refs.formLayout.$refs.form.allDisabled = false
    //       });
    //     } else {
    //       this.$refs.formLayout.$refs.form.allDisabled = false
    //     }
    //
    //
    //   })
    //
    // },
    init() {
      getMenu(this.dataForm.id).then(res => {
        const {data} = res.data;
        this.dataForm = data;
      })
    },

    initData() {
      getMenuTree().then(res => {
        const column = this.findObject(this.column, "parentId");
        column.dicData = res.data.data;
      });
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    //国际化字段：菜单名称的校验
    validateName(rule, value, callback) {
      if (value != '') {
        let val = JSON.parse(value)
        let language = this.language
        let val2 = val[language]
        if (val2.substring(0, 1) == " " || val2.substring(val2.length - 1) == " ") {
          callback(new Error(this.$t("cip.cmn.rule.deleteWarning")));
        }
        if (val2.length > 30) {
          callback(new Error(this.$t("cip.plat.app.appMenu.field.name") + this.$t("cip.cmn.rule.nameLength3Warning")));
        } else {
          callback();
        }
      } else {
        callback(new Error(this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.app.appMenu.field.name")));
      }
    },
    //颜色的校验
    validateColor(rule, value, callback) {
      if (value.length > 30) {
        callback(new Error(this.$t("cip.plat.app.appMenu.field.color") + this.$t("cip.cmn.rule.nameLength3Warning")));
      } else {
        callback()
      }
    },
  }
}
</script>

<style>
</style>
